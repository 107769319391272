import React from 'react';
import { Link, graphql, HeadProps, PageProps } from 'gatsby';
import { Col, Row, Container, Alert } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import Breadcrumb from '../components/Layout/breadcrumb';
import { useLocation } from '@reach/router'

import Meta from '../components/Layout/head';
import Layout from '../components/Layout/layout';
import HikeCell from '../components/Hike/HikeCard';
import ParkCell from '../components/Park/ParkCard';
import ParksCarousel from '../components/Park/ParksCarousel';
import Search from '../components/search/search';

import media from '../images/default-social-share.jpg';

const isBrowser = () => typeof window !== "undefined"; // Detection needed for gatsby build

export const Head = ({location}: HeadProps) => {
    const pathname = isBrowser() ? location.pathname : null; // Get current Url
    const locationRouter = useLocation();
    const params = isBrowser() ? new URLSearchParams(locationRouter.search) : null;
    const getQueryParams = params ? params.get('q') : null;
    const recherche = getQueryParams ? DOMPurify.sanitize(getQueryParams) : null; 

    return (
        <Meta 
            title={`Recherche : ${recherche}`} 
            description={`Résultat(s) pour votre recherche ${recherche}.`}
            lang={'fr'}
            pathname={pathname}
            media={media}> 
        </Meta>
    )
}

export default function SearchPage({data, location}: PageProps<Queries.SearchPageQuery>) {

    const breadcrumbArray = [['/recherche/','Recherche']]; 

    // Globals data variables
    const allParks = data.allContentfulPark;
    const allHikes = data.allContentfulHike;

    // Get Url search parameters
    const params = isBrowser() ? new URLSearchParams(location.search) : '';
    let searchParam: string | null = null;

    let ShowResultTitle: () => JSX.Element | null = () => { return null };
    let ShowHikesSearch: () => JSX.Element | null = () => { return null };
    let ShowParksSearch: () => JSX.Element | null = () => { return null };

    // Purify SearchParam
    if(params) {
        const getQueryParams = params.get('q');
        searchParam = getQueryParams ? DOMPurify.sanitize(getQueryParams) : null;
    
        if(searchParam) {
            // Create Hikes & Parks filter (case-insensitive with toLowerCase)
            const hikesFilter = allHikes.nodes.filter(node=> node.title?.toLowerCase().replace('-', ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchParam!.toLowerCase().replace('-', ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
            const parksFilter = allParks.nodes.filter(node=> node.title?.toLowerCase().replace('-', ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchParam!.toLowerCase().replace('-', ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
    
            const hikesNumber = hikesFilter.length;
            const parksNumber = parksFilter.length;
            const resultNumber = hikesNumber + parksNumber;
    
            ShowHikesSearch = () => {
                // Check if there is hikes, and show them if there are some.
                if(hikesNumber > 1) {
                    return(
                        <div className='mb-3'>
                            <h2>Randonnées <span className='infoTitleMd'>• {hikesNumber} randonnées correspondent à votre recherche</span></h2>
                            <HikesResult />
                        </div>
                    )
                } else if (hikesNumber === 1) {
                    return(
                        <div className='mb-3'>
                            <h2>Randonnées <span className='infoTitleMd'>• {hikesNumber} randonnée correspond à votre recherche</span></h2>
                            <HikesResult />
                        </div>
                    )
                } else if(hikesNumber === 0 && parksNumber > 0) {
                    return (
                        <div className='mb-3'>
                            <h2>Randonnées</h2>   
                            <p>Aucune randonnée liée à cette recherche est disponible.</p>
                        </div>
                    )
                }
                return null;
            }
        
            // Get every hikes filtered by showSearchResults() function
            const HikesResult = () => {
                return(
                    <Row>
                        {            
                        hikesFilter.map((data) => (
                            <Col xs='12' sm='6' md='6' lg='4' className='mb-3' key={data.id}>
                                <HikeCell {...data} />
                            </Col>
                        ))
                        }
                    </Row>
                )
            }
        
            // If there is parks corresponding to the query, show every parks
            ShowParksSearch = () => {
        
                // Check if there is parks, and show them if there are some.
                if(parksNumber > 1) {
                    return(
                        <div>
                            <h2>Réserves naturelles <span className='infoTitleMd'>• {parksNumber} parcs correspondent à votre recherche</span></h2>
                            <ParksResult />
                        </div>
                    )
                } else if(parksNumber === 1) {
                    return(
                        <div>
                            <h2>Réserves naturelles <span className='infoTitleMd'>• {parksNumber} parc correspond à votre recherche</span></h2>
                            <ParksResult />
                        </div>
                    )
                } else if (parksNumber === 0){
                    return (
                        <>
                            <h2>Parcs</h2>   
                            <p>Aucune réserve naturelle liée à cette recherche.</p>                 
                        </>
                    );
                }
                return null;      
            }
        
            // Get every parks filtered by showSearchResults() function
            const ParksResult = () => {
                return(
                    <Row>
                        {            
                        parksFilter.map((data) => (
                            <Col xs='12' sm='6' md='6' lg='4' className='mb-3' key={data.id}>
                                <ParkCell park={data} />
                            </Col>
                        ))
                        }
                    </Row>
                )
            }
        
            ShowResultTitle = () => {
                if(resultNumber > 1) {
                    return (
                        <>
                            <h1 dangerouslySetInnerHTML={{__html: `${resultNumber} résultats de recherche pour : ${searchParam}`}} />
                        </>
                    )
                } else {
                    return (
                        <>
                            <h1 dangerouslySetInnerHTML={{__html: `${resultNumber} résultat de recherche pour : ${searchParam}`}} />
                        </>
                    )
                }
            }
    
        }

    } else {
        ShowResultTitle = () => {
            return (
                <>
                    <h1>Veuillez entrer une requête pour compléter votre recherche.</h1>
                </>
            )
        }
    }
    
    // Return the park content
    return(
        <Layout>
            <div className='green'>
                <Container>
                    <div className='text-center pt-3'>
                        <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                        <Alert className='bg-light-yellow border-0'>
                            <ShowResultTitle />
                            <Row>
                                <Col md={{span:6,offset:3}} sm={{span:10,offset:1}} xs='12'>
                                    <Search />
                                </Col>
                            </Row>
                        </Alert>
                    </div>
                </Container>


                <Container className='container-md'>
                    <Row>
                        <Col xs='12' md='12' lg='9'>
                            {params && (
                                <>
                                    <ShowHikesSearch />
                                    <ShowParksSearch />
                                </>
                            )}
                        </Col>

                        <Col xs='12' md='12' lg='3'>
                            <div className='sidebar'>
                            </div>
                        </Col>

                    </Row>
                </Container>

                                

                <Container className='py-5'>
                    <div>
                        <h2>Découvrir d'autres réserves naturelles</h2>
                        <Link to={`/randonnees/`}>Voir tous les parcs naturels où randonner ‣</Link>
                        <ParksCarousel data={allParks}/>
                    </div>
                </Container>
            </div>
                

        </Layout>
    )

}



export const SearchPageQuery = graphql`
query SearchPage
 {
    allContentfulPark(filter: {node_locale: {eq: "fr"}}) {
        ...AllParksList
    }
    allContentfulHike(filter: {node_locale: {eq: "fr"}}) {
        ...AllHikesList
    }
  }  
`